<template>
  <div class="chats">
    <div v-for="(group, groupIndex) in messageGroups" :key="groupIndex" class="chat"
         :class="{ 'chat-left': group.senderType === 1 }">
      <div v-if="group.senderType === 2" class="chat-avatar">
        <initials-avatar v-if="group.user" class="avatar-border-2 box-shadow-1" variant="transparent" size="36"
                         :family-name="group.user.familyName" :given-name="group.user.givenName" :show-title="true" />
      </div>
      <div class="chat-body">
        <div v-for="message in group.messages" :key="message.id" class="chat-content d-flex">
          <div class="d-flex align-content-center">
            <div class="align-items-start align-content-center">
              <feather-icon v-if="message.icon" class="m-50" :icon="message.icon" size="28" />
            </div>
            <div class="d-flex flex-column">
              <p v-if="message.type === 3" class="text-danger">
                Appel manqué
              </p>
              <p v-else-if="message.type === 4">
                Appel: {{ convertHMS(message.callDuration) }}
              </p>
              <message-content :message="message" />
              <small class="text-right">{{ formatDate(message.date) }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate, convertHMS } from '@core/utils/filter'
import InitialsAvatar from '@/components/InitialsAvatar.vue'
// import { BImg } from 'bootstrap-vue'
import MessageContent from './MessageContent.vue'

export default {
    components: {
        InitialsAvatar,
        MessageContent,
    },
    props: {
        messages: {
            type: Array,
            required: true,
        },
    },
    setup() {
        return {
            formatDate,
            convertHMS,
        }
    },
    computed: {
        messageGroups() {
            const groups = []
            let currentGroup = {
                messages: [],
                senderType: 1,
                user: null,
                userId: null,
            }
            this.messages
                .map(message => {
                    let icon = null
                    switch (message.type) {
                        case 3:
                            icon = 'PhoneMissedIcon'
                            break
                        case 4:
                            icon = 'PhoneCallIcon'
                            break
                        case 5:
                            icon = 'AlertCircleIcon'
                            break
                        default:
                            break
                    }
                    return {
                        ...message,
                        icon,
                    }
                })
                .reverse()
                .forEach(message => {
                    if (message.userId === currentGroup.userId && message.senderType === currentGroup.senderType) {
                        currentGroup.messages.push(message)
                    } else {
                        if (currentGroup.messages.length > 0) {
                            groups.push(currentGroup)
                        }
                        currentGroup = {
                            messages: [message],
                            senderType: message.senderType,
                            user: message.user,
                            userId: message.userId,
                        }
                    }
                })
            if (currentGroup.messages.length > 0) {
                groups.push(currentGroup)
            }
            currentGroup = { ...currentGroup }
            return groups
        },
    },
}
</script>
