<template>
  <article v-if="helper.userId" class="profile-comments-bloc d-flex">
    <initials-avatar v-if="helper.userFamilyName" class="avatar-border-2 comment-section mb-5 mr-2"
      variant="transparent" size="36" :family-name="helper.userFamilyName" :given-name="helper.userGivenName"
      :show-title="true" />
    <div class="flex-grow-1">
      <p class="m-0">
        {{ fullName }}
      </p>
      <div class="mb-2 text-muted">
        {{ formatDate(helper.updatedDate) }}
      </div>
      <p v-if="!isModifyingComment" class="mb-2">
        {{ helper.comment }}
      </p>

      <textarea v-else v-model="comment" rows="4" type="text" class="form-control mb-2" />
      <div v-if="user && user.name.toUpperCase() === fullName.toUpperCase()" class="ml-auto mb-1 d-table">
        <b-button v-if="isModifyingComment" variant="danger" class="mr-1" @click="cancelModification()">
          Annuler
        </b-button>
        <b-button variant="outline-primary" @click="setIsModif()">{{ isModifyingComment ? "Valider" : "Modifier"
          }}</b-button>
      </div>
    </div>
  </article>
</template>

<script>
import store from '@/store' // eslint-disable-line
import InitialsAvatar from '@/components/InitialsAvatar.vue'
import { formatDate } from '@core/utils/filter'
import { BButton } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    InitialsAvatar,
    BButton,
  },
  props: {
    helper: {
      type: Object,
      required: true,
    },
    young: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      formatDate,
    }
  },
  data() {
    return {
      isModifyingComment: false,
      comment: this.helper.comment,
      fullName: `${this.helper.userFamilyName} ${this.helper.userGivenName}`,
    }
  },
  computed: {
    ...mapGetters({
      user: 'users/currentUser',
    }),
  },
  watch: {
    'helper.comment': {
      handler(newVal) {
        if (this.comment !== newVal) {
          this.comment = newVal
        }
      },
    },
    'helper.userFamilyName': {
      handler(newVal) {
        this.fullName = `${newVal} ${this.helper.userGivenName}`
      },
    },
    'helper.userGivenName': {
      handler(newVal) {
        this.fullName = `${this.helper.userFamilyName} ${newVal}`
      },
    },
  },
  methods: {
    async setIsModif() {
      this.isModifyingComment = !this.isModifyingComment
      if (!this.isModifyingComment) {
        const body = {
          comment: this.comment,
          commentId: this.helper.id,
          youngId: this.young.id,
        }
        await store.dispatch('youngs/updateComment', body)
        this.$emit('updateComment', this.helper.id, this.comment)
      }
    },
    cancelModification() {
      this.isModifyingComment = false
      this.comment = this.helper.comment
    },
  },
}
</script>

<style lang="scss" scoped>
.comment-section {
  width: fit-content;
  height: fit-content;
}
</style>
