import Vue from 'vue'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import vuexOidcCreateStoreModule from '@/store/oidc' // eslint-disable-line

const signalrConnection = new HubConnectionBuilder()
  .withUrl(`${window.config.websocketBaseUrl}?access_token=${localStorage.getItem('token')}`)
  // SignalR token access recommendation (doesnt work)
  // .withUrl(window.config.websocketBaseUrl, { accessTokenFactory: () => vuexOidcCreateStoreModule.state.access_token })
  .withAutomaticReconnect()
  .configureLogging(LogLevel.Information)
  .build()

Vue.prototype.$socket = signalrConnection

export default signalrConnection
