<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content" :class="{ 'show': mqShallShowLeftSidebar }">

        <!-- Header -->
        <div class="chat-fixed-search">
          <b-input-group class="input-group-merge w-100 round">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-prepend>
            <b-form-input v-model="searchQuery" placeholder="Rechercher..." />
          </b-input-group>
          <feather-icon v-show="mqShallShowLeftSidebar" class="ml-1 cursor-pointer" icon="XIcon" size="20"
                        @click="$emit('update:mq-shall-show-left-sidebar', false)" />
        </div>

        <!-- Youngs -->
        <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="chat-user-list-wrapper list-group scroll-area">
          <ul class="chat-users-list contact-list media-list">
            <young-contact v-for="(young, index) in filteredYoungs" :key="young.id" :young="young"
                           :class="[{ 'active': activeChatYoungId === young.id }, { 'archived': youngs[index].conversationStatus === 3 }]"
                           tag="li" @click="$emit('open-chat', young.id)" />
          </ul>
        </vue-perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import { BInputGroup, BInputGroupPrepend, BFormInput } from 'bootstrap-vue'
import { computed, ref } from '@vue/composition-api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import SearchMatcher from '@/utils/SearchMatcher'
import { formatDate } from '@core/utils/filter'
import { isDate, isPhoneNumber, formatPhoneNumber } from '@core/utils/utils'
import YoungContact from './YoungContact.vue'

export default {
  components: {
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    VuePerfectScrollbar,
    YoungContact,
  },
  props: {
    youngs: {
      type: Array,
      required: true,
    },
    activeChatYoungId: {
      type: Number,
      default: null,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // Search
    const searchQuery = ref('')
    const searchMatcher = computed(() => new SearchMatcher(searchQuery.value))
    const isQueryDate = computed(() => isDate(searchQuery.value))
    const isQueryPhoneNumber = computed(() => isPhoneNumber(searchQuery.value))
    const filteredYoungs = computed(() => {
      if (isQueryDate.value) {
        const shortDate = props.youngs.filter(y => {
          const formattedDate = formatDate(y.dateOfBirth, 'DD/MM/YYYY')
          return formattedDate === searchQuery.value
        })
        const longDate = props.youngs.filter(y => {
          const formattedDate = formatDate(y.dateOfBirth, { day: 'numeric', month: 'long', year: 'numeric' })
          return formattedDate === searchQuery.value
        })
        if (shortDate.length > 0) {
          return shortDate
        }
        return longDate
      }

      if (isQueryPhoneNumber.value) {
        return props.youngs.filter(y => {
          const formattedPhoneNumber = y.phoneNumber.replace(/\s/g, '')
          return formattedPhoneNumber === formatPhoneNumber(searchQuery.value)
        })
      }
      return props.youngs.filter(y => searchMatcher.value.match(
        y.address,
        y.city,
        y.observation,
        y.email,
        y.firstName?.toLowerCase(),
        y.lastName?.toLowerCase(),
        y.phoneNumber,
        y.zipCode,
        y.lastMessageUser ? y.lastMessageUser.firstName : null,
        y.lastMessageUser ? y.lastMessageUser.lastName : null,
        formatDate(y.dateOfBirth, { day: 'numeric', month: 'long', year: 'numeric' }),
        formatDate(y.dateOfBirth, 'DD/MM/YYYY'),
      ))
    })
    return {
      perfectScrollbarSettings,

      // Search
      searchQuery,
      filteredYoungs,
    }
  },
}
</script>
