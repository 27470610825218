<template>
  <header class="user-profile-header">
    <span class="close-icon">
      <feather-icon icon="XIcon" size="20" @click="$emit('hide-sidebar', false)" />
    </span>

    <div class="header-profile-sidebar">
      <h4 v-if="!isModifying" class="chat-user-name">
        {{ young.lastName }} {{ young.firstName }}
      </h4>
    </div>
  </header>
</template>

<script>

export default {
  props: {
    young: {
      type: Object,
      required: true,
    },
    isModifying: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
