<template>
    <div class="image-popin" @click.self="$emit('closePopin')">
        <div class="image-popin_inner p-2">
            <b-link class="image-popin-close_btn" @click="$emit('closePopin')">
                <feather-icon :icon="'XIcon'" size="28" />
            </b-link>
            <b-img :src="imageUri" class="image-popin_image" />
        </div>
    </div>
</template>
<script>
import { BImg, BLink } from 'bootstrap-vue'

export default {
    components: {
        BImg,
        BLink,
    },
    props: {
        imageUri: {
            required: true,
            type: String,
        },
    },
}
</script>

<style lang="scss">
@import "@/assets/scss/views/youngs.scss";

.image-popin {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-popin_inner {
    border-radius: 4px;
    background: $white;
}

.image-popin_image {
    max-width: 90vw;
    max-height: 90vh;
}

.image-popin-close_btn {
    position: absolute;
    top: 0px;
    right: 0px;
    cursor: pointer;
    color: $red;
}

.dark-layout {
    .image-popin_inner {
        background: $theme-dark-card-bg;
    }
}
</style>
