<template>
  <div v-if="young.id" class="user-profile-sidebar" :class="{ 'show': shallShowYoungCommentsSidebar }">
    <!-- Header -->
    <header class="user-profile-header">
      <span class="close-icon">
        <feather-icon icon="XIcon" size="20" @click="$emit('update:shall-show-young-comments-sidebar', false)" />
      </span>
      <div class="header-profile-sidebar">
        <h4 class="chat-user-name">
          {{ young.lastName }} {{ young.firstName }}
        </h4>
      </div>
    </header>
    <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="user-profile-sidebar-area scroll-area">
      <div class="profile-comments">
        <div class="new-comment">
          <p v-if="isModifyingComment">
            Nouveau commentaire
          </p>
          <textarea v-if="isModifyingComment" v-model="comment" rows="4" type="text" class="form-control mb-2" />
          <div class="ml-auto mb-1 d-table" :class="{ 'add-comment': !isModifyingComment }">
            <b-button v-if="isModifyingComment" variant="danger" class="mr-1" @click="cancelModification()">
              Annuler
            </b-button>
            <b-button variant="outline-primary" @click="setIsModif()">
              {{ isModifyingComment ? "Valider" : "Ajouter un commentaire" }}
            </b-button>
          </div>

        </div>
        <young-comment v-for="(helper, index) in youngComments" :key="index" :helper="helper" :young="young"
                       @updateComment="updateComment" />
      </div>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import store from '@/store'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { formatDate } from '@core/utils/filter'
import { BButton } from 'bootstrap-vue'
import YoungComment from './YoungComment.vue'

export default {
  components: {
    VuePerfectScrollbar,
    YoungComment,
    BButton,
  },
  props: {
    shallShowYoungCommentsSidebar: {
      type: Boolean,
      required: true,
    },
    young: {
      type: Object,
      required: true,
    },
    youngComments: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    return {
      perfectScrollbarSettings,
      formatDate,
    }
  },
  data() {
    return {
      isModifyingComment: false,
      comment: '',
    }
  },
  methods: {
    updateComment() {
      this.$emit('updateYoungComments', this.young.id)
    },
    async setIsModif() {
      this.isModifyingComment = !this.isModifyingComment
      if (!this.isModifyingComment) {
        const body = {
          comment: this.comment,
          youngId: this.young.id,
        }
        await store.dispatch('youngs/addComment', body)
        this.updateComment()
        this.comment = ''
      }
    },
    cancelModification() {
      this.isModifyingComment = false
      this.comment = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.new-comment {
  border-bottom: 1px solid #ebe9f1;
  padding-top: 15px;
  textarea {
    resize: none;
  }
}
.add-comment {
  margin-right: auto;
  margin-top: -1rem;
}
</style>
