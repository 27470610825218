import { render, staticRenderFns } from "./YoungChat.vue?vue&type=template&id=f7c8418e&"
import script from "./YoungChat.vue?vue&type=script&lang=js&"
export * from "./YoungChat.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports