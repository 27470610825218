import signalrConnection from '@signalrConnection'
import store from '@/store'

export default function useYoungsSocket(storeModuleName) {
  const registerSocket = () => {
    signalrConnection.on('ReceiveMessage', message => {
      store.dispatch(`${storeModuleName}/addOrUpdateMessage`, message)
    })
    signalrConnection.on('ReceiveYoung', young => {
      store.dispatch(`${storeModuleName}/addOrUpdateYoung`, young)
    })
    signalrConnection.on('DeleteYoung', youngId => {
      store.dispatch(`${storeModuleName}/deleteYoung`, youngId)
    })
    signalrConnection.start()
  }

  const unregisterSocket = () => {
    signalrConnection.stop()
    signalrConnection.off('ReceiveMessage')
    signalrConnection.off('ReceiveYoung')
  }

  return { registerSocket, unregisterSocket }
}
