<template>
  <div class="youngs-content">
    <div class="body-content-overlay" :class="{ 'show': mqShallShowLeftSidebar }"
         @click="mqShallShowLeftSidebar = false" />

    <section class="youngs-chat">
      <div class="body-content-overlay" :class="{ 'show': shallShowYoungDetailsSidebar }"
           @click="shallShowYoungDetailsSidebar = false" />
      <div class="body-content-overlay" :class="{ 'show': shallShowYoungCommentsSidebar }"
           @click="shallShowYoungCommentsSidebar = false" />

      <div v-if="!activeYoung.id" class="start-chat-area">
        <div class="start-chat-icon" :class="{ 'cursor-pointer': isWindowSmall }" @click="openLeftSidebar">
          <feather-icon icon="UserIcon" size="56" />
        </div>
      </div>
      <div v-else class="active-chat">
        <!-- Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">
            <div class="d-flex align-items-center">
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon icon="MenuIcon" class="cursor-pointer" size="21" @click="mqShallShowLeftSidebar = true" />
              </div>
              <div class="cursor-pointer d-flex align-items-center" @click="shallShowYoungDetailsSidebar = true">
                <h6 class="mb-0 ml-50 mr-1">
                  {{ activeYoung.lastName }} {{ activeYoung.firstName }}
                </h6>
                <p class="mb-0 mr-1">
                  {{ formatPhoneNumber(activeYoung.phoneNumber) }}
                </p>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <b-button v-if="activeYoung.conversationStatus === 3" class="status-bouton" variant="primary"
                        @click="!changeConversationStatus(activeYoung.id)">Mettre en
                attente</b-button>
              <b-button variant="outline-primary" class="icon-container mr-1"
                        @click="shallShowYoungCommentsSidebar = true;">
                <p>Historique</p>
                <feather-icon icon="MessageSquareIcon" size="17" class="d-sm-block d-none mr-50" />
              </b-button>
              <b-button variant="outline-primary" class="icon-container" @click="shallShowYoungDetailsSidebar = true">
                <p>Fiche Jeune</p>
                <feather-icon icon="UserIcon" size="17" class="d-sm-block d-none mr-50" />
              </b-button>
            </div>
          </header>
        </div>
        <!-- Chat -->
        <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="user-chats scroll-area">
          <young-chat :messages="messages" />
        </vue-perfect-scrollbar>
      </div>
    </section>

    <!-- Young Details Sidebar -->
    <young-details :shall-show-young-details-sidebar.sync="shallShowYoungDetailsSidebar" :young="activeYoung"
                   @open-chat="openChat" />
    <young-comments :shall-show-young-comments-sidebar.sync="shallShowYoungCommentsSidebar" :young="activeYoung"
                    :young-comments="youngsComment" @open-chat="openChat" @updateYoungComments="updateYoungComments" />

    <!-- Young List Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <young-list :youngs="youngs" :active-chat-young-id="activeYoung ? activeYoung.id : null"
                  :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar" @open-chat="openChat" />
    </portal>
  </div>
</template>

<script>
import store from '@/store' // eslint-disable-line
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app' // eslint-disable-line
import { formatPhoneNumber } from '@core/utils/filter' // eslint-disable-line
import {
  computed, ref, onUnmounted,
} from '@vue/composition-api'
import { BButton } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { $themeBreakpoints } from '@themeConfig'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-cycle
import useYoungsSocket from './useYoungsSocket'
import YoungChat from './YoungChat.vue'
import YoungDetails from './details/YoungDetails.vue'
import YoungComments from './YoungComments.vue'
import YoungList from './YoungList.vue'

export default {
  components: {
    BButton,
    VuePerfectScrollbar,
    YoungChat,
    YoungDetails,
    YoungComments,
    YoungList,
  },

  setup() {
    const YOUNGS_STORE_MODULE_NAME = 'youngs'
    const { registerSocket, unregisterSocket } = useYoungsSocket(YOUNGS_STORE_MODULE_NAME)

    store.dispatch(`${YOUNGS_STORE_MODULE_NAME}/fetchYoungs`)
    registerSocket()

    // Unregister socket connection
    onUnmounted(() => {
      unregisterSocket()
    })

    // UI
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const isWindowSmall = computed(() => store.state.app.windowWidth < $themeBreakpoints.lg)
    const openLeftSidebar = () => {
      if (isWindowSmall.value) {
        mqShallShowLeftSidebar.value = true
      }
    }
    const perfectScrollbarSettings = { maxScrollbarLength: 150 }
    const shallShowYoungDetailsSidebar = ref(false)
    let shallShowYoungCommentsSidebar = ref(false)

    const fetchComment = youngId => {
      store.dispatch(`${YOUNGS_STORE_MODULE_NAME}/fetchYoungComments`, youngId)
      shallShowYoungCommentsSidebar = true
    }

    const activeYoung = computed(() => store.getters[`${YOUNGS_STORE_MODULE_NAME}/activeYoung`])
    const messages = computed(() => store.getters[`${YOUNGS_STORE_MODULE_NAME}/messages`])
    const youngs = computed(() => store.getters[`${YOUNGS_STORE_MODULE_NAME}/youngs`])
    const youngsComment = computed(() => store.getters[`${YOUNGS_STORE_MODULE_NAME}/youngsComment`].sort((a, b) => new Date(b.date) - new Date(a.date)))

    const updateYoungComments = youngId => {
      fetchComment(youngId)
    }

    const openChat = youngId => {
      store.dispatch(`${YOUNGS_STORE_MODULE_NAME}/setActiveYoungId`, youngId)
      mqShallShowLeftSidebar.value = false
      fetchComment(youngId)
    }

    const toast = useToast()

    const displayToast = (title, icon, mode) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          position: 'bottom-right',
          icon,
          variant: mode,
        },
      })
    }
    const changeConversationStatus = youngId => {
      store.dispatch(`${YOUNGS_STORE_MODULE_NAME}/changeConversationStatus`, youngId)
        .then(() => {
          displayToast('Conversation mise en attente', 'CheckIcon', 'success')
        })
        .catch((error => {
          displayToast(error.toString(), 'XCircleIcon', 'danger')
        }))
    }

    return {
      messages,
      youngs,
      youngsComment,
      updateYoungComments,

      // Chat
      activeYoung,
      openChat,
      changeConversationStatus,
      fetchComment,

      // UI
      formatPhoneNumber,
      toast,
      displayToast,
      mqShallShowLeftSidebar,
      isWindowSmall,
      openLeftSidebar,
      perfectScrollbarSettings,
      shallShowYoungDetailsSidebar,
      shallShowYoungCommentsSidebar,
    }
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/views/youngs.scss";
</style>
