<template>
  <component :is="tag" v-on="$listeners">
    <div class="chat-info flex-grow-1">
      <div v-if="young.lastName || young.firstName" class="d-flex align-items-center">
        <h5 class="chat-name">
          {{ young.lastName }} {{ young.firstName }}
          <feather-icon v-if="young.isBlocked" icon="MinusCircleIcon" fill="red" stroke="white" size="18" />
        </h5>
        <b-badge v-if="young.unreadMessages" pill variant="primary" class="mb-50 ml-1">
          {{ young.unreadMessages }}
        </b-badge>
      </div>
      <div class="d-flex align-items-center card-text text-truncate">
        <feather-icon icon="SmartphoneIcon" />
        <span class="phone-number mr-2">{{ formatPhoneNumber(young.phoneNumber) }}</span>
        <template v-if="young.zipCode">
          <feather-icon icon="MapPinIcon" />
          {{ young.zipCode.substring(0, 2) }}
        </template>
      </div>
    </div>

    <div class="chat-meta text-nowrap d-flex align-items-end flex-column">
      <span v-if="young.conversationStatus === 3">
        <feather-icon icon="ArchiveIcon" />
      </span>
      <small class="mb-25 chat-time">{{ formatDateToMonthShort(young.lastMessageDate, {
        hour: 'numeric', minute: 'numeric'
      }) }}</small>
      <initials-avatar v-if="young.lastMessageUser" size="26" :family-name="young.lastMessageUser.familyName"
                       :given-name="young.lastMessageUser.givenName" :show-title="true" />
    </div>
  </component>
</template>

<script>
import { BBadge } from 'bootstrap-vue'
import { formatDateToMonthShort, formatPhoneNumber } from '@core/utils/filter'
import InitialsAvatar from '@/components/InitialsAvatar.vue'

export default {
  components: {
    BBadge,
    InitialsAvatar,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    young: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      formatDateToMonthShort,
      formatPhoneNumber,
    }
  },
}
</script>

<style>
.blocked {
  align-self: end;
}
</style>
