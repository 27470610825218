<template>
  <div v-if="message.type === 2 && message.mediaType === '.jpg'">
    <b-img v-if="src" :src="src" alt="" fluid width="200%" @click="imageClick(src)" />
    <p v-else>
      Chargement du media...
    </p>
    <p class="align-items-start">
      {{ message.body }}
    </p>
    <image-popin v-if="showFullscreenImage" :image-uri="mediaUri" @closePopin="showFullscreenImage = false" />
  </div>
  <div v-else-if="message.type === 2 && isMp3">
    <ChatAudioPlayer :url="src" :playerid="message.id" />
  </div>
  <p v-else class="align-items-start">
    {{ message.body }}
  </p>
</template>

<script>
import { ref } from '@vue/composition-api'
import { BImg } from 'bootstrap-vue'
import ChatAudioPlayer from '@/components/ChatAudioPlayer.vue'
import ImagePopin from '@/components/Image.popin.vue'
import axiosIns from '@/libs/axiosIns'

export default {
  components: { BImg, ChatAudioPlayer, ImagePopin },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const mediaUri = ref('')
    const showFullscreenImage = ref(false)

    function imageClick(uri) {
      mediaUri.value = uri
      showFullscreenImage.value = true
    }

    return {
      mediaUri,
      showFullscreenImage,
      imageClick,
    }
  },
  data() {
    return {
      src: '',
    }
  },
  computed: {
    isMp3() {
      return this.message.mediaUri?.endsWith('.mp3')
    },
  },
  beforeMount() {
    this.getMedia()
  },
  methods: {
    async getMedia() {
      if (this.message.type !== 2 || !this.message.mediaUri) {
        return
      }

      try {
        const data = await axiosIns.get(this.message.mediaUri, {
          responseType: 'arraybuffer',
        })
        const contentType = data.headers['content-type']
        const mediab64 = Buffer.from(data.data, 'binary').toString('base64')
        this.src = `data:${contentType};base64, ${mediab64}`
      } catch (e) {
        this.src = 'media not found'
      }
    },
  },

}
</script>
